<script>
export default {
  data() {
    return {
      componentLoading: true,
    }
  },
  methods: {
    componentLoaded() {
      this.componentLoading = false
      this.$emit('componentLoaded')
    },
  },
}
</script>
