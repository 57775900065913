import { notifications } from '~/app/notifications'
import AppTitle from '@theme/components/atom/AppTitle'
import ComponentLoadingMixin from '~/mixins/ComponentLoadingMixin'
import Divider from '@theme/components/molecule/Divider'
import HeadingLevel from '~/mixins/HeadingLevel'
import ProductBox from '@theme/components/product/ProductBox'
import ProductTeaser from '@theme/components/product/ProductTeaser'
import ProductsVisitedItem from '@theme/components/product/ProductsVisitedItem'

/**
 * Component checks if there are fetched product entities on mount.
 * It watches for changes of lastVisitedIds and triggers new products fetch.
 */
export default {
  mixins: [ComponentLoadingMixin, HeadingLevel],
  components: {
    AppTitle,
    Divider,
    ProductBox,
    ProductTeaser,
    ProductsVisitedItem,
  },
  props: {
    headingClass: {
      type: String,
      default: 'Title--beta',
    },
  },
  data() {
    return {
      allIDsDeleted: false,
    }
  },
  watch: {
    productsLastVisitedIds: {
      async handler() {
        await this.fetchProducts()
        this.componentLoaded()
      },
      immediate: true,
    },
  },
  computed: {
    productsLastVisited() {
      return this.$themeSettings.components.ProductsVisited.enable ? this.$store.state.product.lastVisitedProducts : []
    },
    productsLastVisitedIds() {
      return this.$store.state.product.lastVisitedIds
    },
  },
  methods: {
    async fetchProducts() {
      // Fetch products only if needed, checked by the first product.
      if (
        !this.$store.state.product.lastVisitedProducts[0] ||
        this.$store.state.product.lastVisitedIds[0] !== this.$store.state.product.lastVisitedProducts[0].id
      ) {
        await this.$store.dispatch('product/FETCH_LAST_VISITED')
      }
    },
    async clear() {
      await this.$store.commit('product/SET_LAST_VISITED_IDS', [])
      this.allIDsDeleted = true
      notifications.success(this.$t('ProductsVisited.notifications.success'))
    },
  },
}
